








































































































































































































































































































































































































































import Vue from 'vue'
import {
  BModal,
  BOverlay,
  BRow,
  BCol,
  BFormGroup,
  BButton,
  BFormInput,
  BFormRadio,
  BvModalEvent,
} from 'bootstrap-vue'

import moment from 'moment'
import flatPickr from 'vue-flatpickr-component'
import { tinyOptions } from '@core/mixins/tinyOptions'
import { flatPickerConfig } from '@core/mixins/flatPickerConfig'
import Editor from '@tinymce/tinymce-vue'
import { getTinymce } from '@tinymce/tinymce-vue/lib/cjs/main/ts/TinyMCE'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required, email } from '@validations'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import store from '@/store'
import AvailablePlaceholder from '@/views/components/available-placeholder/AvailablePlaceholder.vue'
import { appScheduledEmails } from '@/store/modules/app-scheduled-emails-list'
import { appCommunicator } from '@/store/modules/app-communicator'
import { groupForSelect, reportForSelect, sessionForSelect } from '@/types'
import transformReportFieldLabel from '@/helpers/transformReportFieldLabel'
import { emailTemplate } from '../../communication/communication-add/types'

export default Vue.extend({
  name: 'EditScheduledEmailModal',
  components: {
    ValidationProvider,
    ValidationObserver,
    BModal,
    BOverlay,
    BRow,
    BCol,
    BButton,
    BFormGroup,
    BFormInput,
    BFormRadio,

    flatPickr,
    Editor,
    vSelect,
    AvailablePlaceholder,
  },
  directives: {
    Ripple,
  },
  mixins: [tinyOptions, flatPickerConfig],
  data() {
    return {
      required,
      email,
      timezone: localStorage.getItem('timezone') || '',
    }
  },
  computed: {
    programId() {
      return store.getters['verticalMenu/getDefaultProgram']
    },
    scheduledEmail() {
      return appScheduledEmails.getters.getScheduledEmail
    },
    communication() {
      return appCommunicator.getters.getCommunication
    },
    communicationTypeOptions() {
      return appCommunicator.getters.getCommunicationTypeOptions
    },
    allAudienceTypesList() {
      return appCommunicator.getters.getAllAudienceTypesList
    },
    allGroupsList() {
      return appCommunicator.getters.getAllGroupsList
    },
    allSessionsList() {
      return appCommunicator.getters.getAllSessionsList
    },
    allReportsList() {
      return appCommunicator.getters.getAllReportsList
    },
    allReportFieldsList() {
      return appCommunicator.getters.getAllReportFieldsList
    },
    allUserRoles() {
      return appCommunicator.getters.getAllUserRolest
    },
    allEmailTemplatesList() {
      return appCommunicator.getters.getAllEmailTemplatesList
    },
    allLetterTemplatesList() {
      return appCommunicator.getters.getAllLetterTemplatesList
    },
    fieldGroupStatus() {
      return appCommunicator.getters.getFieldGroupStatus
    },
    fieldSessionStatus() {
      return appCommunicator.getters.getFieldSessionStatus
    },
    fieldReportStatus() {
      return appCommunicator.getters.getFieldReportStatus
    },
    fieldTypeStatus() {
      return appCommunicator.getters.getFieldTypeStatus
    },
    fieldTemplateStatus() {
      return appCommunicator.getters.getFieldTemplateStatus
    },
    fieldLetterTemplateStatus() {
      return appCommunicator.getters.getFieldLetterTemplateStatus
    },
    isLoading() {
      return appCommunicator.getters.getIsLoading
    },
    allRecurringTypesList() {
      return appCommunicator.getters.getAllRecurringTypesList
    },
  },
  methods: {
    async setModalData() {
      appCommunicator.mutations.SET_IS_LOADING(true)
      await this.fetchGroups()
      await this.fetchSessions()
      await this.fetchReportsList()
      await this.fetchCommunicationTypes()
      await this.fetchAllEmailTemplatesList()
      await this.fetchLetterTemplatesList()
      await this.setEmailData()
      appCommunicator.mutations.SET_IS_LOADING(false)
    },
    setEmailData() {
      if (this.scheduledEmail) {
        const typeId = this.scheduledEmail.data.communication_type_id
        const selectedSessionId: sessionForSelect[] | any[] = this.scheduledEmail?.data.session_id

        const communication = {
          id: this.scheduledEmail.id,
          audienceType: this.scheduledEmail.data.audience_type,
          group: this.allGroupsList.find((group:any) => group.id === this.scheduledEmail?.data.group_id) as groupForSelect || null,
          session: this.allSessionsList.filter((session: any) => selectedSessionId.includes(session.id)) as sessionForSelect[] || [],
          report: this.allReportsList.find((report:any) => report.id === this.scheduledEmail?.data.report_id) as reportForSelect || null,
          reportField: this.scheduledEmail.data.report_field || null,
          communicationType: this.communicationTypeOptions.find(el => el.id === typeId) || null,
          content: this.scheduledEmail.data.message,
          emailTemplate: this.allEmailTemplatesList.find((item:any) => item.id === this.scheduledEmail?.data.email_template) as emailTemplate || null,
          letterTemplate: this.scheduledEmail.data.letter_template || null,
          role: this.scheduledEmail.data.role,
          fromName: this.scheduledEmail.data.from_name,
          fromEmail: this.scheduledEmail.data.from_email,
          sendAt: moment.tz(this.scheduledEmail.send_at, this.timezone).toDate(),
          schedule: 'later',
          subject: this.scheduledEmail.subject,
          recurring: this.scheduledEmail.recurring || null,
        }
        if (communication.report) {
          this.setReportFields(communication.report)
        }

        appCommunicator.mutations.SET_COMMUNICATION(communication)
      }
    },
    async fetchCommunicationTypes() {
      await await appCommunicator.actions.fetchCommunicationTypes()
    },
    async fetchAllEmailTemplatesList() {
      await appCommunicator.actions.fetchEmailTemplatesList()
    },
    async fetchLetterTemplatesList() {
      await appCommunicator.actions.fetchLetterTemplatesList()
    },
    async fetchGroups() {
      await appCommunicator.actions.fetchGroups()
    },
    async fetchSessions() {
      await appCommunicator.actions.fetchSessions()
    },
    async fetchReportsList() {
      await appCommunicator.actions.fetchReportsList()
    },
    initCommunicationData() {
      appCommunicator.mutations.SET_COMMUNICATION({
        content: '',
        audienceType: null,
        session: [],
        group: null,
        report: null,
        reportField: null,
        role: 'All',
        communicationType: null,
        emailTemplate: null,
        letterTemplate: null,
        fromName: '',
        fromEmail: '',
        schedule: 'now',
        sendAt: null,
        subject: '',
        recurring: null,
      })
    },
    handleForm(bvModalEvt: BvModalEvent) {
      bvModalEvt.preventDefault()

      return new Promise((resolve, reject) => {
        (this.$refs.scheduledEmailRules as InstanceType<typeof ValidationObserver>)
          .validate().then(success => {
            if (success) {
              resolve(true)
              this.saveChanges()
            } else {
              window.scrollTo(0, 0)
              reject()
            }
          })
      })
    },
    async saveChanges() {
      await appCommunicator.actions.editScheduledEmail()
      this.$bvModal.hide('edit-scheduled-email')
      this.$emit('emailUpdated')
    },
    resetModal() {
      this.initCommunicationData()
      this.$emit('resetScheduledEmail')
    },
    selectedEmailTemplate() {
      if (this.communication.emailTemplate) {
        this.communication.content = this.communication.emailTemplate.body
        this.communication.subject = this.communication.emailTemplate.subject
      }
    },
    insertValueToEditor(val: string) {
      getTinymce().activeEditor.execCommand('mceInsertContent', false, val)
    },
    setReportFields(report: reportForSelect) {
      const reportFields = report.selected_fields.reduce((fields, item) => {
        fields.push({
          label: transformReportFieldLabel(item),
          value: item,
        })
        return fields
      }, [] as {label: string, value: string}[])
      appCommunicator.mutations.SET_ALL_REPORT_FIELDS_LIST(reportFields)
    },
    updateReportFields(report: reportForSelect) {
      this.communication.reportField = null
      this.setReportFields(report)
    },
  },
})
