












































































































































































import Vue from 'vue'
import {
  BCard, BRow, BCol, BFormInput, BTable,
  BPagination, BDropdown, BDropdownItem,
} from 'bootstrap-vue'

import { filters } from '@core/mixins/filters'
import vSelect from 'vue-select'
import TableSpinner from '@/views/components/table-spinner/TableSpinner.vue'
import EditScheduledEmailModal from '@/views/admin/scheduled-emails/edit-modal/EditScheduledEmailModal.vue'
import { appScheduledEmails } from '@/store/modules/app-scheduled-emails-list'
import { IScheduledEmailResponseItem } from '@/store/modules/app-scheduled-emails-list/types'
import pushStateFiltersParams from '@/helpers/pushStateFiltersParams'

let debounceTimeout: ReturnType<typeof setTimeout> = setTimeout(() => '', 1000)

export default Vue.extend({
  name: 'ScheduledEmailsList',
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BTable,
    BPagination,
    BDropdown,
    BDropdownItem,

    vSelect,

    TableSpinner,
    EditScheduledEmailModal,
  },
  mixins: [filters],
  data() {
    return {
      currentRouteName: this.$router.currentRoute.name,
    }
  },
  computed: {
    tableColumns() {
      return appScheduledEmails.getters.getTableColumns
    },
    perPage() {
      return appScheduledEmails.getters.getPerPage
    },
    totalItems() {
      return appScheduledEmails.getters.getTotalItems
    },
    currentPage: {
      get() {
        return appScheduledEmails.getters.getCurrentPage
      },
      set(val: number) {
        appScheduledEmails.mutations.SET_CURRENT_PAGE(val)
      },
    },
    perPageOptions() {
      return appScheduledEmails.getters.getPerPageOptions
    },
    searchQuery: {
      get() {
        return appScheduledEmails.getters.getSearchQuery
      },
      set(val: string) {
        appScheduledEmails.mutations.SET_SEARCH_QUERY(val)
      },
    },
    sortBy() {
      return appScheduledEmails.getters.getSortBy
    },
    isSortDirDesc() {
      return appScheduledEmails.getters.getIsSortDirDesc
    },
    dataMeta() {
      return appScheduledEmails.getters.getDataMeta
    },
    scheduledEmail() {
      return appScheduledEmails.getters.getScheduledEmail
    },
  },
  watch: {
    $route() {
      if (!this.$route.query.currentPage) {
        this.setFilterParams()
      }
      this.updateTable()
    },
    currentPage(val, prevVal) {
      if (val !== prevVal) {
        pushStateFiltersParams(this, this.currentRouteName, 'currentPage', val)
      }
    },
    perPage(val, prevVal) {
      if (val !== prevVal) {
        pushStateFiltersParams(this, this.currentRouteName, 'perPage', val)
      }
    },
    searchQuery(val, prevVal) {
      if (val !== prevVal) {
        clearTimeout(debounceTimeout)
        debounceTimeout = setTimeout(() => {
          pushStateFiltersParams(this, this.currentRouteName, 'searchQuery', val)
        }, 250)
      }
    },
  },
  mounted() {
    this.setFilterParams()
    // Update filters
    window.onpopstate = () => {
      this.setFilterParams()
    }
  },
  methods: {
    async fetchScheduledEmailsList() {
      return appScheduledEmails.actions.fetchScheduledEmailsList()
    },
    updatePerPage(val: number) {
      appScheduledEmails.mutations.SET_PER_PAGE(val)
    },
    setFilterParams() {
      appScheduledEmails.mutations.SET_FILTER_PARAMS()
    },
    showEditModal(email: IScheduledEmailResponseItem) {
      appScheduledEmails.mutations.SET_SCHEDULED_EMAIL(email)
      this.$nextTick(() => {
        this.$bvModal.show('edit-scheduled-email')
      })
    },
    async cancelEmail(id: number | string) {
      await appScheduledEmails.actions.cancelScheduledEmail(id)
      this.updateTable()
    },
    emailUpdated() {
      this.updateTable()
      this.resetScheduledEmail()
    },
    resetScheduledEmail() {
      appScheduledEmails.mutations.SET_SCHEDULED_EMAIL(null)
    },
    updateTable() {
      (this.$refs.refScheduledEmailsListTable as BTable).refresh()
    },
  },
})
